import React from "react";
import "./PhoneLink.css"; // Import the CSS file

const PhoneLink = () => {
  return (
    <div>
      <a href="tel:+18082980235" className="phone-link">
        Contact us at: +1 808-298-0235
      </a>
    </div>
  );
};

export default PhoneLink;