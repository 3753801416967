import React from 'react';
import { useHistory } from 'react-router-dom';
import './Block.css';

const Block = () => {
  const history = useHistory();

  const blocks = [
    {
      imageSrc: '/static/images/babysitter.jpg',
      title: 'Tutti Babysitters and Childcare',
      text: 'At Tutti, our babysitters are carefully verified and specialize in working with traveling families. Through the Tutti platform, you can communicate directly with babysitters, check their availability and easily book services. Explore reviews from a wide range of experienced babysitters and select the one that best suits your needs. Enjoy a stress-free experience, knowing help is just a click away!',
      buttonText: 'See Tutti Babysitters',
      url: '/s?address=Maui%2C%20Hawaii%2C%20USA&bounds=21.03141298976477%2C-155.9790420834986%2C20.57457850828407%2C-156.6969232882344&pub_category=babysitter&sort=meta_reviewsAverage',
    },
    {
      imageSrc: '/static/images/tuttiHelper.jpg',
      title: 'Tutti for Vacation Rental Guests',
      text: 'Staying in a vacation rental? Tutti has you covered. Tutti offers an easy-to-use platform to connect with verified local residents to find most of your needs.',
      buttonText: `Explore Tutti's offerings for vacation rental guests`,
      url: '/p/vacation-rentals',
    },
  ];

  return (
    <div className="block-container">
      {blocks.map((block, index) => (
        <div className="block-item" key={index}>
          <img
            src={block.imageSrc}
            alt={block.title}
            className="block-image"
            onClick={() => history.push(block.url)}
          />
          <div className="block-content">
            <h2 className="block-title">{block.title}</h2>
            <p className="block-text">{block.text}</p>
            <button
              className="block-button"
              onClick={() => history.push(block.url)}
            >
              {block.buttonText}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Block;
